<template>
    <ValidationProvider
        :name="validationName"
        :rules="validationRules"
        v-slot="{ errors }"
    >
        <v-textarea
            dense
            :disabled="inputDisabled"
            :error-messages="errors"
            filled
            hide-details="auto"
            :hint="inputHint"
            :id="id"
            outlined
            persistent-hint
            :placeholder="inputPlaceholder"
            :prepend-inner-icon="inputIcon"
            :readonly="inputReadOnly"
            :rows="inputRows"
            :type="inputType"
            :value="inputValue"
            v-bind="$attrs"
            @blur="$emit('blur', $event)"
            @input.native="$emit('input', $event)"
        />
    </ValidationProvider>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

extend("password", {
    params: ["target"],
    validate(value, { target }) {
        return value === target;
    },
    message: "Password doesn't match.",
});

export default {
    name: "customTextAreaField",
    props: {
        errors: { default: null },
        id: { default: null },
        inputDisabled: { default: false },
        inputHint: { default: null },
        inputIcon: { default: null },
        inputPlaceholder: { default: "Placeholder" },
        inputReadOnly: { default: false },
        inputRows: { default: 4 },
        inputType: { default: "text" },
        inputValue: { default: "" },
        validationName: { default: "ValidationProviderName" },
        validationRules: { default: null },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

